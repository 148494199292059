import * as Types from '../types.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {"context":{"bff":true}}
export type GetMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me: { __typename?: 'UserPreferences', userId: string, name: string, email: string, phone: string, role: string, datePattern: string, isAdmin: boolean, isBlocked: boolean, isOnboarded: boolean, isAuthorized: boolean, permissions: Array<string>, language: string, country?: { __typename?: 'Country', id: string, name: string, countryCode: string } | null, region?: { __typename?: 'Region', id: string, name: string } | null, area: { __typename?: 'Unit', id: string, name: string }, weight: { __typename?: 'Unit', id: string, name: string }, length: { __typename?: 'Unit', id: string, name: string }, volume: { __typename?: 'Unit', id: string, name: string }, areaDensity: { __typename?: 'Unit', id: string, name: string }, density: { __typename?: 'Unit', id: string, name: string }, group: { __typename?: 'Group', id: string, name: string, integrations: Array<{ __typename?: 'Integration', id: string, name: string, status: boolean }> } } };


export const GetMeDocument = gql`
    query GetMe {
  me {
    userId
    name
    email
    phone
    role
    datePattern
    isAdmin
    isBlocked
    isOnboarded
    isAuthorized
    permissions
    language
    country {
      id
      name
      countryCode
    }
    region {
      id
      name
    }
    area {
      id
      name
    }
    weight {
      id
      name
    }
    length {
      id
      name
    }
    volume {
      id
      name
    }
    areaDensity {
      id
      name
    }
    density {
      id
      name
    }
    group {
      id
      name
      integrations {
        id
        name
        status
      }
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;