import React from 'react'
import { CacheProvider, css, Global } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import NotoSansFont from '../fonts/noto-sans-font'
import IbmPlexMonoFont from '../fonts/ibm-plex-mono-font'
import { theme } from '../theme'
import createCache from '@emotion/cache'

type ThemeProps = {
  theme?: Theme
  children: any
}

const getEmotionCache = () => {
  const cache = createCache({ key: 'ayra-emotion', prepend: true })
  cache.compat = true

  return cache
}

const printSpecificStyles = /** CSS **/ `        
          @media print {
            a {
              page-break-inside: avoid;
            }
            blockquote {
              page-break-inside: avoid;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            section,
            article {
              page-break-after: avoid;
              page-break-inside: avoid;
            }
            img {
              page-break-inside: avoid;
              page-break-after: avoid;
            }
            table,
            pre {
              page-break-inside: avoid;
            }
            ul,
            ol,
            dl {
              page-break-before: avoid;
            }

            html,
            body,
            #root,
             {
              font-size: var(--root-font-size-px, 10px) !important;
              // font-size: 10px !important;
            }

            @page {
              size: A4 landscape;
            }
          }
`
const GlobalStyle = ({ children, theme: customTheme }: ThemeProps): JSX.Element => {
  if (customTheme && customTheme.overrides && !customTheme.overrides.MuiCssBaseline) {
    customTheme.overrides.MuiCssBaseline = {
      '@global': {
        html: {
          fontSize: '14px',
        },
      },
    }
  }

  const cache = getEmotionCache()

  return (
    <CacheProvider value={cache}>
      <Global
        styles={css`
          ${printSpecificStyles}

          ${NotoSansFont};
          ${IbmPlexMonoFont};
          :root {
            --root-font-size: 14;
            --root-font-size-px: 14px;
            --1rem: calc(calc(var(--root-font-size-px) / var(--root-font-size)) * 0.1rem);

            //[data-debug] {
            --debug-outline-opacity: 80%;
            --debug-outline-color-deg: 299deg;
            --debug-outline-color: hsl(var(--debug-outline-color-deg) 100% 50% / var(--debug-outline-opacity));
            --debug-outline-type: dotted;
            --debug-outline-border: 1px var(--debug-outline-type) var(--debug-outline-color);
            --debug-height: 100%;
            --debug-width: 100%;
            //width: fit-content;
            //height: fit-content;
            //}

            @media print {
              --root-font-size: 10;
              --root-font-size-px: 10px;
            }

            // print-specific config
            --milimiter-multiplier: 0.2645833333;
            //--a4-width: calc(297mm * var(--milimiter-multiplier));
            //--a4-height: calc(210mm * var(--milimiter-multiplier));
          }

          [data-section],
          [data-page] {
            color: white;
            position: relative;
            //--hue-change: calc(attr(data-page) * 1 + 1);
            //--hue-calc: calc(var(--debug-outline-color-deg) * var(--hue-change, 1));
            &::before {
              --debug-outline-color: hsl(var(--debug-outline-color-deg) 100% 50% / var(--debug-outline-opacity));

              position: absolute;
              content: '.';
              inset: 0;
              width: var(--debug-width, 100%);
              height: var(--debug-height, 100%);
              outline: var(--debug-outline-border);
            }

            &::after {
              content: attr(data-actual-height);
              text-shadow: 0 0.5px hsla(0, 0%, 0%, 0.5);
              background-color: var(--debug-outline-color);

              border-radius: 0.5rem;
              padding: 0.1rem 0.1rem;
              position: absolute;
              right: -1rem;
              top: -1rem;
            }
          }

          [data-page] {
            --hue-change: calc(calc(attr(data-page) + 1) * 190 / 360);
            //--debug-outline-color: hsl(var(--hue-change) 100% 50% / var(--debug-outline-opacity));
            //
            //--debug-outline-color-deg: 150deg;
            //--debug-outline-opacity: 58%;
            //--debug-outline-type: dashed;

            --debug-width: var(--a4-width);
            --debug-height: var(--a4-height);
            //outline: 4px inset var(--debug-outline-color);

            & ::after {
              top: 2rem;
            }

            & ::before {
              outline: 4px inset limegreen;

              //outline: 4px inset var(--debug-outline-color);
            }
            //outline-style: groove;
            //outline-width: 4px;
          }

          //:root {
          //  //--root-font-size-px: calc(var(--root-font-size) * 1px);
          //  //--1rem: calc(var(--root-font-size-px) / 14 * 0.1rem);
          //}

          html,
          body,
          #root {
            height: 100%;
            font-size: var(--root-font-size-px);
            font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
            background-color: white !important;
            margin: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          .MuiPopover-paper {
            scrollbar-width: thin;
            scrollbar-color: #00205b transparent;
          }

          .MuiPopover-paper::-webkit-scrollbar {
            background-color: transparent;
            width: 5px;
          }

          .MuiPopover-paper::-webkit-scrollbar-track {
            background-color: transparent;
          }

          .MuiPopover-paper::-webkit-scrollbar-thumb {
            background-color: #00205b;
            border-radius: 3px;
          }

          .mapboxgl-ctrl-top-left {
            opacity: 0;
          }

          code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
          }

          a {
            text-decoration: none;
          }

          p a,
          p a:visited {
            color: #1370bc;
          }

          .absoluteCenter {
            bottom: 0;
            left: 0;
            position: absolute;
            margin: auto;
            right: 0;
            top: 0;
          }

          .AppNavContentSplit {
            display: flex;
            min-height: 100%;
          }

          .AppContentLayout {
            display: flex;
            flex-direction: column;
            width: calc(100% - 120px);
            margin: 0 auto;
            justify-content: space-between;
          }

          .AppContentLayout header,
          .AppContentLayout footer {
            box-sizing: border-box;
            flex-basis: 98px;
          }

          .AppContentLayout main {
            flex-grow: 1;
          }
        `}
      />
      <ThemeProvider theme={customTheme ?? theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  )
}

export default GlobalStyle
