import React from 'react'
import { MsalProvider } from '@azure/msal-react'
import AuthProviderAzure from './AuthProviderAzure'

const AuthProviderSwitch = ({ azurePublicClientApp, children }) => {
  return (
    <MsalProvider instance={azurePublicClientApp}>
      <AuthProviderAzure>{children}</AuthProviderAzure>
    </MsalProvider>
  )
}

export default AuthProviderSwitch
